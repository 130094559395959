.loading.cover-content {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
.loading.cover-page {
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.loading .ant-spin {
  color: #3e79f7;
}

.ant-collapse .ant-collapse-item .ant-collapse-header {
  padding: 0px !important;
}

.wrap-credits{
	float:right;
}
.animation-wrap{
	float: left;
	display: inline;
}
.credits-text{
	margin-top: 13px;
	margin-right: 13px;
	float: left;
	display: inline;
	font-size: 12px;
	font-family:sans-serif;
	font-weight: 100;
}
.pi{
	width: 40px;
	height: 40px;
	position: relative;
	-webkit-transform-style: preserve-3d;
	transform-style: preserve-3d;
	-webkit-transition: .4s ease;
	transition: .4s ease;
}
.adelante{
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
}
.atras img{
	width: 40px;
	height: 40px;
}
.atras{
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
}
.adelante{
	width: 100%;
}
.atras{
	-webkit-transform: rotateY(180deg);
	transform: rotateY(180deg);
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	text-align: center;
	color: #fff;
	font-family: "open sans";
}
.animation-wrap:hover .pi{
	-webkit-transform: rotateY(180deg);
	transform: rotateY(180deg);
}
.pi1{
	background-image: url(https://www.pi-interactiva.com/wp-content/uploads/2022/05/Pi-Interactiva.png);
	background-size: cover;
}
.pi1-white{
	background-image: url(https://www.pi-interactiva.com/wp-content/uploads/2022/09/pi_white.png);
	background-size: cover;
}

.logoMain{
	position: absolute;
	margin-top: 80%;
	margin-left: 50%;
}

.check{
	width: 20px;
	height: 20px;
	margin-top: 6px;
}

.pausar {
	background-color: #e6fff1;
	border: 3px solid #7cf7bc; 
	border-radius: 10px;
	border-style: dashed;
	color: #252525;
	font-weight: 400;
	height: auto;
	margin: 1rem 0px 1rem 0px;
	max-width: 85%;
}
.pausar:hover{
	background-color: #64e6a7;
}

.despausar {
	background-color: #ffb2af6b;
	border: 3px solid #ffa19d; 
	border-style: dashed;
	border-radius: 10px;
	color: #252525;
	font-weight: 400;
	height: auto;
	margin: 0px 0px 1rem 0px;
	max-width: 85%;
}
.despausar:hover{
	background-color: #ffa19d;
}

.btn-danger:disabled {
	cursor: not-allowed;
}